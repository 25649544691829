import React from "react";
import utils from "../utils";
import OptionListRoute from './OptionList_Route';

export const ajax_url = '/animal_species';
export const route_path = "/animal_species";
export const title = "動物種類";

export function redirect_route(){
    utils.redirect_route(route_path);
}

export function redirect_href(){
    utils.redirect_href(route_path);
}

function AnimalSpeciesList_Route(){
    return <OptionListRoute
        title={title}
        ajax_url={ajax_url}
    />
}

AnimalSpeciesList_Route.propTypes = {};

export default AnimalSpeciesList_Route;
