import './App.css';
import React from 'react';
import {QueryClient, QueryClientProvider} from 'react-query'
import {Route, Switch} from "react-router";
import CssBaseline from '@material-ui/core/CssBaseline';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {configureStore} from './store';
import history from './history';
import routes from './routes';
import MainLayout from './layouts/MainLayout';
import ErrorRoute from './routes/Error_Route';
import SPCATheme from "./themes/SPCATheme";
import AppProgress from "./components/AppProgress";

const queryClient = new QueryClient({
    defaultOptions:{
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
        }
    }
});

const App = (()=>{
    return <QueryClientProvider client={queryClient}>
        <AppProgress />
        <SPCATheme>
            <CssBaseline>
                <Provider store={configureStore()}>
                    <ConnectedRouter history={history}>
                        <Switch>
                            {
                                routes.map((route_props, index) => {
                                    const {path, exact = false, layout, is_login_required, is_app_bar, component, background_color} = route_props;

                                    return <Route
                                        key={`ROUTE_${index}`}
                                        exact={exact}
                                        path={path}
                                        layout={layout}
                                        component={() =>
                                            <MainLayout
                                                background_color={background_color}
                                                is_login_required={is_login_required}
                                                is_app_bar={is_app_bar}
                                                component={component}
                                            />
                                        }
                                    />
                                })
                            }
                            <Route exact component={() => <MainLayout
                                is_login_required={false}
                                is_app_bar={false}
                                component={ErrorRoute}/>
                            } />
                        </Switch>
                    </ConnectedRouter>
                </Provider>
            </CssBaseline>
        </SPCATheme>
    </QueryClientProvider>
});

export default App;
