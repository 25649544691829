import React from "react";
import styled from "styled-components";
import IconButton from '@material-ui/core/IconButton';
import FirstPage from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {ROWS_PER_PAGE} from "../../../config";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Left = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.75rem;
  font-weight: 400;
`;


const Pagination = ({page, totalCount, onChange}) => {
    const fromRecord = (page * ROWS_PER_PAGE) +1;
    const toRecord = Math.min( (page + 1) * ROWS_PER_PAGE, totalCount);
    const lastPage = Math.floor(totalCount / ROWS_PER_PAGE);
    const isLastPage = page === lastPage;

    return <Container>
        <Left>
            {fromRecord}-{toRecord}
            &nbsp;of&nbsp;
            {totalCount}
        </Left>
        <IconButton
            onClick={()=>onChange(0)}
            disabled={page === 0}
        >
            <FirstPage />
        </IconButton>
        <IconButton
            onClick={()=>onChange(page-1)}
            disabled={page === 0}
        >
            <KeyboardArrowLeft />
        </IconButton>

        <IconButton
            onClick={()=>onChange(page+1)}
            disabled={isLastPage}
        >
            <KeyboardArrowRight />
        </IconButton>
        <IconButton
            onClick={()=>onChange(lastPage)}
            disabled={isLastPage}
        >
            <LastPageIcon />
        </IconButton>
    </Container>
};

export default Pagination;
