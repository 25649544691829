import React, {Fragment} from 'react';
import useHeaders from './useHeaders';
import useCells from './useCells';
import utils from "../../../utils";
import MyTable from "../../../components/Shared/MyTable";
import CenterLoading from "../../../components/CenterLoading";
import {useCaseList} from "../../../queries/case";

const CaseList = () => {
    const cells = useCells();
    const headers = useHeaders();
    const {isLoading, data} = useCaseList();

    if(isLoading){
        return <Fragment />
    }

    const {records=[], total_count: totalCount} = data.data;
    if(isLoading){
        return <CenterLoading />
    }
    else{
        return <MyTable
            headers={headers}
            cells={cells}
            records={records.sort(utils.compareByPriority)}
            totalCount={totalCount}
        />
    }
}

CaseList.defaultProps = {
};

CaseList.propTypes = {
};

export default CaseList;
