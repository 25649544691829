import _ from "lodash";
import React from "react";
import KeyValueField from "./KeyValueField";
import utils from "../../../utils";
import StatusSelect from "../../../components/StatusSelect";
import UndertakerSelectContainer from "../../../containers/UndertakerSelect";
import TextArea from "../../Shared/TextArea";
import getUrgentLevelColor from "../../../routes/CaseList_Route/CaseCards/CaseCard/getUrgentLevelColor";
import Panel from "../shared/Panel";
import {
    useUpdateCurrentBrief,
    useUpdateDropboxUrl,
    useUpdateIsPublic,
    useUpdateOverallBrief,
    useUpdateStatus,
    useUpdateUndertaker,
    useUpdateUrgentLevel
} from "../../../queries/case";
import useCaseNum from "../shared/useCaseNum";
import UrgentSelect from "../../Shared/UrgentSelect";
import IOSSwitch from "../../IOSSwitch";
import {ALink} from "../../Shared/Link/Link";
import useEnabledFinishedStatus from "../../../hooks/useEnabledFinishedStatus";
import useEnabledPendingStatus from "../../../hooks/useEnabledPendingStatus";

const CaseOneBasicInformation = ({caseOne}) => {
    const caseNum = useCaseNum();
    const updateIsPublic = useUpdateIsPublic();
    const updateStatus = useUpdateStatus();
    const updateUrgentLevel = useUpdateUrgentLevel();
    const updateUndertaker = useUpdateUndertaker();
    const updateOverallBrief = useUpdateOverallBrief();
    const updateCurrentBrief = useUpdateCurrentBrief();
    const updateDropboxUrl = useUpdateDropboxUrl();
    const enabledPending = useEnabledPendingStatus();
    const enabledFinished = useEnabledFinishedStatus();

    const {
        time_of_sighting,
        finished_date,
        created_date,
        status,
        urgent_level,
        undertaker,
        overall_brief,
        current_brief,
        is_public,
        dropbox_url
    } = caseOne;

    return <Panel title={caseNum} titleColor={getUrgentLevelColor(urgent_level)}>
        <KeyValueField left="發現日期" right={utils.toTaipeiDateStr(time_of_sighting)} />
        <KeyValueField left="報案日期" right={utils.toTaipeiDateStr(created_date)} />
        <KeyValueField left="結案日期" right={finished_date===null ? "": utils.toTaipeiDateStr(finished_date)} />
        <KeyValueField left="案件狀態" right={<StatusSelect
            disabled={updateStatus.isLoading}
            enabledFinished={enabledFinished}
            enabledPending={enabledPending}
            value={status}
            onChange={updateStatus.mutate}
        />} />
        <KeyValueField left="緊急程度" right={<UrgentSelect disabled={updateUrgentLevel.isLoading} label="" value={urgent_level} onChange={updateUrgentLevel.mutate} />} />
        <KeyValueField left="承辦人" right={<UndertakerSelectContainer
            disabled={updateUndertaker.isLoading}
            value={undertaker}
            onChange={updateUndertaker.mutate}
        />} />
        <KeyValueField left="案件簡述" right={<TextArea maxLength={40} value={_.isEmpty(overall_brief)? "": overall_brief} onChange={updateOverallBrief.mutate} />} />
        <KeyValueField left="處理簡述" right={<TextArea value={_.isEmpty(current_brief)? "" : current_brief} onChange={updateCurrentBrief.mutate} />} />
        <KeyValueField left="對外開放" right={<IOSSwitch disabled={updateIsPublic.isLoading} checked={is_public} onChange={event=> updateIsPublic.mutate(event.target.checked) } />} />
        <KeyValueField left="Dropbox" right={<div>
            <TextArea value={dropbox_url} onChange={updateDropboxUrl.mutate} />
            <ALink style={{display: "block", marginTop: ".5rem"}} href={dropbox_url} target="_blank">連結</ALink>
        </div>} />
    </Panel>
};

export default CaseOneBasicInformation;
