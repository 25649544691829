import React, {Fragment, useState} from 'react';
import {useQueryClient} from "react-query";
import MyTable from '../MyTable';
import utils from '../../../utils/utils';
import ChangePasswordDialog from "../../ChangePasswordDialog/ChangePasswordDialog";
import {useUserList, useUserPasswordChange} from "../../../queries/user";
import queryKeys from "../../../queries/queryKeys";
import useHeaders from "./useHeaders";
import useCells from "./useCells";

const UserTable = () => {
    const headers = useHeaders();
    const queryClient = useQueryClient();
    const [id, setId] = useState("");
    const [open, setOpen] = useState(false);
    const {isLoading = false, data} = useUserList();
    const changePasswordMutation = useUserPasswordChange();
    const cells = useCells({onChangePasswordClick(){
        setId(id);
        setOpen(true);
    }});

    if(isLoading){
        return <Fragment />
    }

    const {records=[], count} = data.data;
    return (
        <>
            <ChangePasswordDialog
                open={open}
                onClose={()=>setOpen(false)}
                onSubmit={(pw_1, pw_2)=>{
                    changePasswordMutation.mutate({id, pw_1, pw_2}, {
                        async onSuccess(){
                            await queryClient.invalidateQueries(queryKeys.USER_ALL);
                            setOpen(false);
                            setId("");
                            utils.openNotification(`密碼更新成功`);
                        }
                    });
                }}
            />
            <MyTable
                headers={headers}
                cells={cells}
                records={records}
                totalCount={count}
            />
        </>
    );
}

UserTable.propTypes = {};

export default UserTable;
