import React, {memo} from 'react';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#125e9f"
        }
    },
});

export default memo(({children})=>{
    return <MuiThemeProvider theme={theme}>
        {children}
    </MuiThemeProvider>
});
