import React from "react";
import PropTypes from "prop-types";
import CenterLoading from "../components/CenterLoading/CenterLoading";
import ErrorMsg from '../components/ErrorMsg';
import OptionList from '../components/OptionList';
import {useMutation, useQuery, useQueryClient} from "react-query";
import getPromise from "../queries/getPromise";
import ajax from "../actions/ajax";

function OptionListRoute({ajax_url, title}){
    const queryKey = ajax_url;
    const queryClient = useQueryClient();

    const {isLoading, data, isError} = useQuery(queryKey, () => getPromise(ajax_url));
    const onSuccess = async data => {
        queryClient.setQueryData(queryKey, data);
    };

    const onDeleteSuccess = async data => {
        queryClient.setQueryData(queryKey, data);
    };

    const addMutation = useMutation(ajax_url, record => ajax.post(ajax_url, record), {
        onSuccess
    });

    const delMutation = useMutation(record => ajax.delete(`${ajax_url}/${record.id}`), {
        onSuccess: onDeleteSuccess
    });

    const updateMutation = useMutation(record => ajax.put(ajax_url, record), {
        onSuccess
    });

    if(isLoading){
        return <CenterLoading />
    }
    else if(isError){
        return <ErrorMsg>發生錯誤</ErrorMsg>
    }
    else{
        const records = data.data;

        return <OptionList
            title={title}
            records={records}
            disabled={updateMutation.isLoading || addMutation.isLoading || delMutation.isLoading}
            onChange={({index, ...record})=>{
                updateMutation.mutate(record);
            }}
            onAdd={record=>{
                addMutation.mutate(record);
            }}
            onDelete={(record)=>{
                if(window.confirm(`確定刪除${record.name}?`)){
                    delMutation.mutate(record);
                }
            }}
        />
    }
}

OptionListRoute.propTypes = {
    ajax_url: PropTypes.string,
    title: PropTypes.string,
};

export default OptionListRoute;
