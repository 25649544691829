import React from "react";
import utils from "../utils";
import OptionListRoute from './OptionList_Route';

export const ajax_url = '/case_type';
export const route_path = "/case_type";
export const title = "案件類型";

export function redirect_route(){
    utils.redirect_route(route_path);
}

export function redirect_href(){
    utils.redirect_href(route_path);
}

function CaseTypeList_Route(){
    return <OptionListRoute
        title={title}
        ajax_url={ajax_url}
    />
}

CaseTypeList_Route.propTypes = {};

export default CaseTypeList_Route;
