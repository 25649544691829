import React from "react";
import MyTextField from "../TextField";

const TextArea = ({maxLength, value, onChange}) => {
    const myTextFieldProps = {
        multiline: true,
        value,
        onChange
    };

    if(maxLength){
        myTextFieldProps.maxLength = maxLength;
    }
    return <MyTextField {...myTextFieldProps} />
};

export default TextArea;
