import {debounce} from "lodash";
import React from "react";
import PropTypes from 'prop-types';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import Title from "../Title";
import AddButtonDialog from "../AddButtonDialog";
import RaisedButton from "../Shared/RaisedButton/RaisedButton";
import TextField from "../Shared/TextField/TextField";
import utils from "../../utils/utils";
import FlexBox from "../FlexBox";
import DefaultContainer from "../DefaultContainer";

function getFirstValue(destinationIndex, records=[], isIncreasing=false){
    if(destinationIndex===0){
        return 0;
    }
    else if (isIncreasing){
        return records[destinationIndex-1].priority;
    }
    else{
        return records[destinationIndex].priority;
    }
}

function getSecondValue(destinationIndex, records=[], isIncreasing=false){
    if(isIncreasing){
        return records[destinationIndex].priority;
    }
    //the last one
    else if(destinationIndex===records.length-1){
        return records[destinationIndex].priority+1;
    }
    else{
        return records[destinationIndex+1].priority;
    }
}

const OptionList = (({disabled=false, title, records=[], onAdd, onDelete, onChange})=>{
    return <DefaultContainer>
        <Title>{title}</Title>
        <AddButtonDialog title={title} onAdd={name=>onAdd({name})} />
        <DragDropContext onDragEnd={({source, destination})=>{
            if (destination) {
                const sourceRecord = records[source.index];
                const destinationRecord = records[destination.index];
                if(sourceRecord.id !== destinationRecord.id){
                    const isIncreasing = sourceRecord.priority > destinationRecord.priority;

                    const firstValue = getFirstValue(destination.index, records, isIncreasing);
                    const secondValue = getSecondValue(destination.index, records, isIncreasing);
                    const newPriority = firstValue + ((secondValue - firstValue) / 2);
                    onChange({...sourceRecord, priority: newPriority, index: source.index});
                }
            }
        }}>
            <Droppable droppableId="droppable">
                {droppableProvided =>
                    <div ref={droppableProvided.innerRef}>
                        {records.sort(utils.compareByPriority).map((record, index)=>{
                            const key = `item-${record.id}`;

                            return <Draggable key={key} draggableId={key} index={index}>
                                {draggableProvided => (
                                    <div
                                        ref={draggableProvided.innerRef}
                                        {...draggableProvided.draggableProps}
                                        {...draggableProvided.dragHandleProps}

                                    >
                                        <FlexBox>
                                            <DragHandleIcon />
                                            <TextField readOnly={disabled}  fullWidth value={record.name} onChange={debounce(name=>{
                                                if(name !== record.name){
                                                    onChange({...record, index, name});
                                                }
                                            }, 500)} />
                                            <RaisedButton disabled={disabled} color="secondary" onClick={()=>onDelete(record, index)}>刪除</RaisedButton>
                                        </FlexBox>
                                    </div>
                                )}
                            </Draggable>
                        })}
                        {droppableProvided.placeholder}
                    </div>
                }
            </Droppable>
        </DragDropContext>
    </DefaultContainer>
});


OptionList.defaultProps = {
    disabled: false,
    title: "",
    records: [],
    onDelete: ()=>{},
    onChange: ()=>{},
    onAdd: ()=>{},
};

OptionList.propTypes = {
    disabled: PropTypes.bool,
    title: PropTypes.string,
    records: PropTypes.array,
    onDelete: PropTypes.func,
    onChange: PropTypes.func,
    onAdd: PropTypes.func
};

export default OptionList;
