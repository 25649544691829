import React from "react";
import utils from "../utils/utils";
import OptionListRoute from './OptionList_Route';

export const ajax_url = '/current_brief';
export const route_path = "/current_brief";

export const title = "處理情況";

export function redirect_route(){
    utils.redirect_route(route_path);
}

export function redirect_href(){
    utils.redirect_href(route_path);
}

function CurrentBriefListRoute(){
    return <OptionListRoute
        title={title}
        ajax_url={ajax_url}
    />
}

CurrentBriefListRoute.propTypes = {};

export default CurrentBriefListRoute;
