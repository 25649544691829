import React from "react";
import utils from "../utils";
import OptionListRoute from './OptionList_Route';

export const ajax_url = '/tag';
export const route_path = "/tag";
export const title = "標籤";

export function redirect_route(){
    utils.redirect_route(route_path);
}

export function redirect_href(){
    utils.redirect_href(route_path);
}

function TagList_Route(){
    return <OptionListRoute
        title={title}
        ajax_url={ajax_url}
    />
}

TagList_Route.propTypes = {};

export default TagList_Route;
