import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import RaisedButton from '../Shared/RaisedButton/RaisedButton';

export default class AddButtonDialog extends React.Component {
    state = {
        open: false,
        value: ""
    };

    handleClickOpen = () => {
        this.setState({ open: true, value: "" });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleOk = ()=>{
        this.handleClose();
        const {onAdd=()=>{}} = this.props;
        onAdd(this.state.value);
    };

    render() {
        const {title=""} = this.props;

        return (
            <div>
                <RaisedButton color="primary" onClick={this.handleClickOpen}>新增</RaisedButton>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            fullWidth
                            value={this.state.value}
                            onChange={e=>this.setState({value: e.target.value})}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            取消
                        </Button>
                        <Button disabled={this.state.value===""} onClick={this.handleOk} color="primary">
                            確定
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}