import React from "react";
import utils from "../utils/utils";
import OptionListRoute from './OptionList_Route';

export const ajax_url = '/complaint_category';
export const route_path = "/complaint_categories";

export const title = "案件問題";

export function redirect_route(){
    utils.redirect_route(route_path);
}

export function redirect_href(){
    utils.redirect_href(route_path);
}

function ComplaintCategoryListRoute(){
    return <OptionListRoute
        title={title}
        ajax_url={ajax_url}
    />
}

ComplaintCategoryListRoute.propTypes = {};

export default ComplaintCategoryListRoute;
